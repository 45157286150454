import Cookies from "js-cookie";
import {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";
import {
  AuthDefaultValueType,
  AuthProviderPropsType,
  AuthRequireOptionsType,
  ImpossibleErrorType,
  LanguagesEnum,
  LoginWithCodeResponse,
} from "../types/authTypes";
import {
  ACCESS_TOKEN_KEY,
  AuthErrorsMessages,
  CODE_URL_PARAM_NAME,
  REFRESH_TOKEN_KEY,
} from "../lib/constants/constants";
import { AuthContext } from "../lib/authContext/authContext";
import { api } from "@Shared/api/createAxiosApi";
import {
  CheckAccessTokenMiddleware,
  CheckAccessTokenMiddlewareOptions,
} from "../lib/middlewares/CheckAccessTokenMiddleware";
import { setUserCookies } from "../lib/setUserCookies/setUserCookies";
import { useRouter } from "next/router";
import UserRoleEnum from "@/lib/enums/userRole";
import clearThemeVariables from "@/lib/themes/clearThemeVariables";
import { useDispatch } from "react-redux";
import { UserActions } from "@Entities/User";
import { Loader } from "@Entities/Loader";
import { getAvailableDegreesByWindow } from "@/lib/getAvailableDegrees";

const defaultProviderValues: AuthDefaultValueType = {
  isAuthenticated: false,
  isLoading: true,
  error: null,
  user: null,
  getAccessToken: null,
  logoutHandler: null,
};

export const AuthProvider: FC<AuthProviderPropsType> = (props) => {
  const { children, lang = LanguagesEnum.RU } = props;

  const dispatch = useDispatch();
  const [providerValues, setProviderValues] = useState<AuthDefaultValueType>(
    defaultProviderValues
  );
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [error, setError] = useState<ImpossibleErrorType>(null);
  const AuthRequireOptions = useRef(
    null
  ) as MutableRefObject<AuthRequireOptionsType | null>;
  const router = useRouter();

  const authUserByCurrentStatus = async (options: AuthRequireOptionsType) => {
    const {
      accessToken,
      refreshToken,
      code,
      redirectUrl,
      lang: locale,
    } = options;

    if (accessToken && refreshToken && !code) {
      handleAuthorization(options);
    }

    if (code) {
      await axios
        .get<LoginWithCodeResponse>("/api/v1/sso/auth", {
          params: {
            code,
            redirect_uri: redirectUrl,
            locale,
          },
        })
        .then(setUserCookies)
        .catch(() => {
          setError(AuthErrorsMessages.AuthWithCodeErrorMessage);
        });
    }

    if (!refreshToken || !accessToken) {
      await axios
        .get("/api/v1/sso/uri/login", {
          params: {
            redirect_uri: redirectUrl,
            locale,
          },
        })
        .then((res) => {
          const authRedirectUrl = res.data.result.uri;
          router.replace(authRedirectUrl);
        })
        .catch(() => {
          setError(AuthErrorsMessages.AuthWithRedirectErrorMessage);
        });
    }

    if (accessToken && refreshToken && code) {
      const { pathname } = router;
      router.replace({ pathname });
      handleAuthorization(options);
    }
  };

  api.interceptors.request.use(async (config) => {
    const options: CheckAccessTokenMiddlewareOptions = {
      AuthRequireOptions: AuthRequireOptions,
      authUserByCurrentStatus: authUserByCurrentStatus,
      config: config,
      lang,
    };
    return CheckAccessTokenMiddleware(options);
  });

  const getAccessToken = useCallback(() => {
    return Cookies.get(ACCESS_TOKEN_KEY);
  }, []);

  const logoutHandler = useCallback(async () => {
    const response = await api.get("/sso/uri/logout", {
      params: {
        redirect_uri:
          (lang === LanguagesEnum.EN ? `/${lang}` : "") + router.asPath,
        locale: lang,
      },
    });

    if (response.data.ok) {
      const logoutUrl = response.data.result.uri;
      clearThemeVariables();
      Cookies.remove(REFRESH_TOKEN_KEY);
      Cookies.remove(ACCESS_TOKEN_KEY);
      router.replace(logoutUrl);
    }
  }, []);

  const handleUserApplicationAccess = async (user: IUserData) => {
    const isWindowUser = user.roles.includes(UserRoleEnum.ROLE_WINDOW);
    const isQuestionnairePage = router.pathname
      .split("/")
      .includes("questionnaire");
    const isWindowRoute = router.pathname.split("/").includes("window");
    const availableDegrees = getAvailableDegreesByWindow(user.windows).length
      ? getAvailableDegreesByWindow(user.windows)
      : user.manager_degrees;

    if (isWindowUser && !isQuestionnairePage && isWindowRoute) {
      return router.push(router.asPath);
    }

    if (isWindowUser && !isQuestionnairePage && !isWindowRoute) {
      return router.push(`/window/${availableDegrees.at(0)}`);
    }
  };

  const handleAuthorization = async ({
    accessToken,
    refreshToken,
  }: AuthRequireOptionsType) => {
    const userResponse = await api.get("/users/");
    const configurationResponse = await api.get("/configurations");

    if (configurationResponse.data.ok) {
      const configuration = configurationResponse.data.result;
      const payload = {
        year: configuration.reception_company_year,
        acceptance: configuration.reception_company_in_process,
        changeAchievement:
          configuration.allowed_postgraduate_change_achievement,
      };
      dispatch(UserActions.setConfiguration(payload));
    }

    if (userResponse.data.ok) {
      const user = userResponse.data.result.user;

      dispatch(UserActions.setUser(user));

      await handleUserApplicationAccess(user);

      setProviderValues({
        isLoading: !isMounted,
        isAuthenticated: Boolean(accessToken && refreshToken),
        error: error,
        user: user,
        getAccessToken: getAccessToken,
        logoutHandler: logoutHandler,
      });
      setIsMounted(true);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href);
    const accessToken = Cookies.get(ACCESS_TOKEN_KEY);
    const refreshToken = Cookies.get(REFRESH_TOKEN_KEY);
    const code = urlParams.get(CODE_URL_PARAM_NAME);
    AuthRequireOptions.current = {
      lang,
      redirectUrl:
        (lang === LanguagesEnum.EN ? `/${lang}` : "") + router.pathname,
      accessToken: accessToken,
      refreshToken: refreshToken,
      code: code,
    };

    authUserByCurrentStatus(AuthRequireOptions.current);
  }, []);

  if (!isMounted) {
    return <Loader infinite />;
  }

  return (
    <AuthContext.Provider value={providerValues}>
      {children}
    </AuthContext.Provider>
  );
};
