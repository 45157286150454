import { useSelector } from "react-redux";
import styles from "./Loader.module.scss";
import { getLoaderStatus } from "../model/selectors/loaderSelector";

export const Loader = ({ infinite = false }) => {
  const isLoading = useSelector(getLoaderStatus);

  return (
    (infinite || isLoading) && (
      <div className={styles.container}>
        <div className={styles.loader}></div>
      </div>
    )
  );
};
