export const apiPrefix = "/api/v1";

let isDev = false;
let programVisible = false;

const processEnvAppEnv = process.env.APP_ENV;
export type IAppEnv = "local" | "dev" | "test" | "stage" | "prod";
export const appEnv: IAppEnv =
    undefined !== processEnvAppEnv && ["local", "dev", "test", "stage", "prod"].includes(processEnvAppEnv)
      ? (processEnvAppEnv as IAppEnv)
      : "dev";

export const formLink = (userID: number, studentDetailsID: number, degree?: string): string => {
  return `/users/${userID}/${degree ? degree : "studentDetails"}/${studentDetailsID}`;
};

export const formFileNamesLink = (urls: string[] | string): string => {
  if (Array.isArray(urls)) return urls.map((url) => "urls[]=" + url).join("&");
  else return "urls[]=" + urls;
};

if (!process.env.APP_ENV || process.env.APP_ENV === "local") {
  isDev = true;
}

if (process.env.PROGRAM_VISIBLE === "1") {
  programVisible = true;
}

export const personalFormLinkPrefix = "/personal";

export const questionnaireFormLinkPrefix = (
  degree: string,
  userId: number,
  studentDetailsId: number
): string => `/window/${degree}/questionnaire/${userId}/${studentDetailsId}`;

export const bachelorFormLink: string[] = [
  "/programs",
  "/personally",
  "/address",
  "/photo",
  "/education",
  "/exams",
  "/relatives",
  "/medical",
  "/military",
  "/privileges",
  "/specialty",
  "/olympiads",
  "/achievements",
  "/itmostars",
  "/kronbars",
  "/dormitory",
];

export const masterFormLink: string[] = [
  "/programs",
  "/personally",
  "/address",
  "/photo",
  "/education",
  "/exams",
  "/relatives",
  "/medical",
  "/military",
  "/achievements",
  "/specialty",
  "/dormitory",
  "/documents",
];

export const postgraduateFormLink: string[] = [
  "/programs",
  "/personally",
  "/address",
  "/photo",
  "/education",
  "/exams",
  "/relatives",
  "/military",
  "/achievements",
  "/specialty",
  "/dormitory",
  "/documents",
];

export const SSRApiHost = process.env.APP_API as string || (isDev ? "http://nginx" : "http://backend");

export const Analytics = {
  vkid: process.env.VK_ID || null,
  ymid: process.env.YM_ID || "37235675",
  gaid: process.env.GA_ID || "GTM-MSDX52Q",
  gtmid: process.env.GTM_ID || "GTM-MSDX52Q",
};

export {
  isDev,
  programVisible,
};
