import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  RatingWindowType,
  SpecialConditionWindowType,
  WindowStateSchema,
  WindowTargetAdmissions,
} from "../types/windowTypes";
import {
  IWindowDiscipline,
  IWindowProgramAdmissionQuota,
  IWindowProgramWithCost,
} from "@/types/window/forms";
import { IDictionaryItem } from "@/lib/services/dictionary.service";
import { IWindowStateQuestionnaire } from "@/types/store";

const initialState: WindowStateSchema = {
  active: null,
  questionnaire: null,
  params: {
    page: 1,
    search: {
      query: "",
      filter: "",
    },
    year: 2023,
  },
  disciplines: [],
  programDisciplines: [],
  spoDisciplines: [],
  foreignerDisciplines: [],
  targetAdmissions: null,
  directionsTraining: [],
  achievementTypes: [],
  specialConditions: {
    items: [],
    total: 0,
  },
  rating: {
    directionTraining: null,
    financing: "budget",
    list: [],
  },
  statistics: {
    columns: null,
    data: null,
  },
  forms: {
    programsCosts: [],
    disciplines: [],
    competitiveGroups: [],
    admissionQuotas: [],
  },
};

const WindowSlice = createSlice({
  name: "window",
  initialState,
  reducers: {
    setWindow: (state, action: PayloadAction<any>) => {
      state.active = action.payload;
    },
    setFormsCompetitiveGroup: (
      state,
      action: PayloadAction<IWindowCompetitiveGroup[]>
    ) => {
      state.forms.competitiveGroups = action.payload;
    },
    setFormsProgramsWithCost: (
      state,
      action: PayloadAction<IWindowProgramWithCost[]>
    ) => {
      state.forms.programsCosts = action.payload;
    },
    setFormsAdmissionQuota: (
      state,
      action: PayloadAction<IWindowProgramAdmissionQuota[]>
    ) => {
      state.forms.admissionQuotas = action.payload;
    },
    setFormsDisciplines: (
      state,
      action: PayloadAction<IWindowDiscipline[]>
    ) => {
      state.forms.disciplines = action.payload;
    },
    setDisciplines: (state, action: PayloadAction<IDictionaryItem[]>) => {
      state.disciplines = action.payload;
    },
    setSpoDisciplines: (state, action: PayloadAction<IDictionaryItem[]>) => {
      state.spoDisciplines = action.payload;
    },
    setForignerDisciplines: (
      state,
      action: PayloadAction<IDictionaryItem[]>
    ) => {
      state.foreignerDisciplines = action.payload;
    },
    setProgramDisciplines: (
      state,
      action: PayloadAction<IDictionaryItem[]>
    ) => {
      state.programDisciplines = action.payload;
    },
    setTargetAdmissions: (
      state,
      action: PayloadAction<WindowTargetAdmissions | null>
    ) => {
      state.targetAdmissions = action.payload;
    },
    setSpecialConditions: (
      state,
      action: PayloadAction<SpecialConditionWindowType>
    ) => {
      state.specialConditions = action.payload;
    },
    setIsEditableMode: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.questionnaire.isEditableMode = action.payload;
    },
    setDictionaryTraining: (state, action: PayloadAction<any[]>) => {
      state.directionsTraining = action.payload;
    },
    setAchievementTypes: (state, action: PayloadAction<IDictionaryItem[]>) => {
      state.achievementTypes = action.payload;
    },
    setWindowQuestionary: (
      state,
      action: PayloadAction<IWindowStateQuestionnaire>
    ) => {
      state.questionnaire = action.payload;
    },
    setRating: (state, action: PayloadAction<RatingWindowType>) => {
      const directionTraining = state.directionsTraining.find(
        (item: { id: number }) => item.id === action.payload.id
      );
      if (directionTraining) {
        (state.rating.directionTraining = directionTraining),
        (state.rating.list = action.payload.list),
        (state.rating.financing = action.payload.financing);
      }
    },
  },
});

export const { reducer: WindowReducer } = WindowSlice;
export const { actions: WindowActions } = WindowSlice;
