import {
  ChangeEvent,
  FC,
  MouseEvent,
  PropsWithChildren,
  useMemo,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import ArrowIcon from "@/public/images/symbols/arrow_down.svg";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import styles from "./Select.module.scss";
import cn from "classnames";

interface SelectProps {
  id?: string;
  value?: MapItem | null;
  options: MapItem[];
  placeholder?: string;
  onChange(value: MapItem | null): void;
  onInput?: (value: string) => void;
  onScroll?: (e: MouseEvent<HTMLUListElement>) => void;
  className?: string;
  disabled?: boolean;
  error?: string;
  disableInput?: boolean;
  label?: string;
  required?: boolean;
  showValue?: boolean;
  onBlur?: () => void;
  showError?: boolean;
}

export const Select: FC<PropsWithChildren<SelectProps>> = (props) => {
  const {
    id,
    value,
    options,
    onChange,
    onInput,
    onScroll,
    placeholder,
    className,
    disabled = false,
    disableInput = false,
    error,
    label,
    required = false,
    showValue = false,
    onBlur,
    showError = true,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState<string>("");
  const selectRef = useRef<HTMLDivElement>(null);
  const isInputChanged = useRef<boolean>(false);

  useOutsideClick(selectRef, () => {
    setIsOpen(false);
    if (query !== value?.label) {
      setQuery("");
    }
  });

  const handleSelectItem = (item: MapItem) => {
    isInputChanged.current = false;
    setIsOpen(false);
    setQuery("");

    onChange(item);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!disableInput) {
      const value = e.target.value;

      isInputChanged.current = true;
      onInput && onInput(value);
      setQuery(value.toLocaleLowerCase());
    }
  };

  const renderOptions = useMemo(
    () =>
      options
        ?.filter((item) =>
          item?.label?.toString().toLowerCase()?.includes(query)
        )
        .map((item) => (
          <li
            className={styles.select__item}
            id={`selectItem-${String(item.value).replace(/\s+/g, "_")}`}
            key={String(item.value)}
            onClick={() => handleSelectItem(item)}
          >
            {item.label}
          </li>
        )),
    [options, query]
  );

  const mods: Record<string, boolean> = {
    [styles.select__disabled]: disabled,
    [styles.select__input_error]: Boolean(error),
  };

  return (
    <div className={styles.select} id={`${id}_wrapper`} ref={selectRef}>
      <label className={styles.select__label} htmlFor={id}>
        {label}
        {required && <span> *</span>}
      </label>

      <input
        aria-autocomplete="none"
        autoComplete="off"
        className={clsx(styles.select__input, className, mods)}
        disabled={disabled}
        id={id}
        placeholder={placeholder}
        value={
          isInputChanged.current
            ? query
            : showValue
              ? value?.label
              : !!value?.value
                ? options?.find(
                  ({ value: optionsValue }) => optionsValue == value?.value
                )?.label ?? value?.label
                : ""
        }
        onBlur={onBlur}
        onChange={handleChangeInput}
        onClick={() => setIsOpen((prev) => !prev)}
      />
      <ArrowIcon className={styles.select__icon} />
      {isOpen && (
        <div className={styles.select__menu}>
          <ul
            className={styles.select__list}
            id={`${id}_menu`}
            onScroll={onScroll}
          >
            {renderOptions}
          </ul>
        </div>
      )}
      {error && showError && (
        <span className={cn(styles.select__error, "invalid-feedback")}>
          {error}
        </span>
      )}
    </div>
  );
};
